const base = {
  appearance: "none",
  outline: "none",
  display: "inline-block",
  textAlign: "center",
  lineHeight: "inherit",
  textDecoration: "none",
  fontSize: "inherit",
  fontFamily: "body",
  letterSpacing: "button",
  cursor: "pointer",
  m: 0,
  py: 2,
  border: 0,
  transitionProperty: "opacity, box-shadow, transform, background-color",
  transitionDuration: "150ms",
  transitionTimingFunction: "ease-out",
  "&[disabled]": {
    opacity: 0.5,
    boxShadow: "none",
    pointerEvents: "none",
  },
  "&:focus": {
    boxShadow: "outline",
  },
};

const basic = {
  fontSize: [18, null, null, 20],
  fontWeight: 800,
  display: "flex",
  alignItems: "center",
  whiteSpace: "nowrap",
};
const basicReverse = {
  fontSize: [18, null, 22, 28],
  fontWeight: 800,
  display: "flex",
  alignItems: "center",
  whiteSpace: "nowrap",
};
const basicNoArrow = {
  fontSize: [18, null, 22, 28],
  fontWeight: 800,
  display: "flex",
  alignItems: "center",
  whiteSpace: "nowrap",
};
export const buttons = {
  base,
  "outline-green": {
    ...base,
    ...basic,
    bg: "white",
    borderRadius: 70,
    border: "solid 3px #8FCA45",
    width: "fit-content",
    color: "brand.green",
    fontWeight: 800,
    p: [12, null, null, 16],
  },
  submit: {
    ...base,
    fontSize: [18, null, null, 20],
    px: 4,
    pb: 3,
    pt: 3,
    fontWeight: "500",
    borderRadius: 30,
    boxShadow: "inset -5px -5px 0px #0000004D",
    bg: "brand.darkBlue",
    color: "white",
  },
  "submit-green": {
    ...base,
    width: 220,
    py: 13,
    fontWeight: "500",
    borderRadius: 30,
    boxShadow: "inset -5px -5px 0px #0000004D",
    bg: "brand.green",
    color: "white",
    fontSize: 4,
    height: 55,
  },
  "outline-green-blue": {
    ...base,
    ...basic,
    bg: "white",
    borderRadius: 70,
    border: "solid 3px #8FCA45",
    width: "fit-content",
    color: "brand.lightBlue",
    fontWeight: 800,
    p: [12, null, null, 16],
  },
  green: {
    ...base,
    ...basic,
    color: "brand.green",
  },
  "green-filled": {
    ...base,
    ...basic,
    color: "white",
    borderRadius: 70,
    bg: "brand.green",
    width: "100%",
  },
  "green-noarrow": {
    ...base,
    ...basicNoArrow,
    color: "brand.green",
  },
  black: {
    ...base,
    ...basic,
    color: "black",
  },
  blue: {
    ...base,
    ...basic,
    color: "#106CAA",
  },
  backBlue: {
    ...base,
    ...basicReverse,
    color: "#106CAA",
  },
  nav: {
    ...base,
  },
};
